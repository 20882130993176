<template>
  <div>
    <SettingsNavigation />

    <div class="max-w-screen-xl mx-auto px-6">
      <TeamCard />
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import TeamCard from '@components/Teams/TeamCard'

export default {
  components: {
    SettingsNavigation,
    TeamCard
  }
}
</script>
