<template>
  <BaseContainer>
    <TeamSettings />
  </BaseContainer>
</template>

<script>
import TeamSettings from '@components/Settings/TeamSettings'

export default {
  components: {
    TeamSettings
  }
}
</script>
