import { pickedApiRequest } from './request'

export default {
  /**
   * Create a new member
   *
   * @param {string} organisationId
   * @param {Object} member
   * @return {Object}
   */
  create (organisationId, member) {
    return pickedApiRequest.post('assessment/organisations/' + organisationId + '/members', member)
      .then(response => {
        return response.data.data
      })
  },

  /**
   * List members
   *
   * @param {string} organisationId
   * @return {Object}
   */
  index (organisationId) {
    return pickedApiRequest.get('assessment/organisations/' + organisationId + '/members')
      .then(response => {
        return response.data.data
      })
  }
}
