<template>
  <div class="bg-white w-full">
    <div class="p-10 sm:flex-shrink-0 max-w-sm text-center mx-auto">
      <Teams />
      <h2 class="text-3xl mt-6 leading-tight mb-2">
        You haven’t added any team members
      </h2>
      <p class="text-gray-600 mb-6">
        Team members will appear here.
      </p>
    </div>
  </div>
</template>

<script>
import Teams from '@components/Vectors/Teams'

export default {
  components: {
    Teams
  }
}
</script>
