<template>
  <div>
    <table
      v-if="tableData"
      class="w-full border-collapse text-gray-800"
    >
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            class="uppercase font-normal text-gray-500 text-left text-xs pb-2"
          >
            <a
              class="flex items-center py-2"
              href="javascript:;"
              @click="sortBy(column)"
            >
              <span>{{ column }}</span>

              <div class="px-2 text-gray-500">
                <svg
                  :class="['fill-current h-4 w-4', { 'transform rotate-180': sortOrders[column] < 0 }]"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
            </a>
          </th>
        </tr>
      </thead>

      <tbody class="text-sm">
        <tr
          v-for="(member, key) in filteredData"
          :key="key"
        >
          <td class="border-t py-4">
            {{ member.name }}
          </td>
          <td class="border-t py-4">
            {{ member.email }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default: null
    },
    tableData: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      sortKey: '',
      sortOrders: {}
    }
  },

  computed: {
    filteredData() {
      let sortKey = this.sortKey
      let order = this.sortOrders[sortKey] || 1

      // Rename the keys to match the column headings
      let data = this.tableData.map(({ firstName, surname, email, createdAt }) => (
        {
          name: `${firstName} ${surname}`,
          email,
          createdAt
        }
      ))

      if (sortKey) {
        data = data.slice().sort((a, b) => {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * order
        })
      }

      // Default data sorting is newest team members first
      if (!sortKey) {
        return data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      } else {
        return data
      }
    }
  },

  created() {
    let sortOrders = {}
    this.columns.forEach(key => {
      sortOrders[key] = 1
    })
    this.sortOrders = sortOrders
  },

  methods: {
    sortBy(key) {
      this.sortKey = key
      this.sortOrders[key] = this.sortOrders[key] * -1
    }
  }
}
</script>
